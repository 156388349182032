<template>
    <div class="bbs-header-ctn">
        <a-layout>
            <a-layout>
                <a-layout-header
                    style="background: #FFFFFF;box-shadow: 0px 4px 10px 0px rgba(78, 89, 105, 0.06); z-index: 100;">
                    <Header></Header>
                </a-layout-header>
                <a-layout-content style="min-height: calc(100vh - 280px);background-color: #FAFAFA;z-index: 10; display: flex; justify-content: center;padding: 0px 0px;">
                    <div style="width: 0; flex: 1; max-width: 1200px; margin: 0px 32px;">
                        <router-view :key="$route.query.ref ? $route.query.ref : 1" v-if="isRouterAlive" class="animate__animated animate__fadeIn"></router-view>
                    </div>
                </a-layout-content>
                <a-layout-footer>
                    <Footer></Footer>
                </a-layout-footer>
            </a-layout>
        </a-layout>
    </div>

</template>

<script>
import Header from '../components/header.vue';
import Footer from '../../../components/common/footer.vue';
export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            isRouterAlive: true,
        }
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            })
        }
    },
    watch: {
        '$store.state.trigger.changePlatformTigger': function () {
            this.reload();
        },
    }
}
</script>

<style lang="less" scoped>
.ant-layout-header {
    margin: 0;
    padding: 0;
    height: auto;
}

.ant-layout-footer {
    margin: 0;
    padding: 0;
}
</style>