<template>
    <div>
        <div class="bbs-header">
            <div class="logo-ctn">
                <div>
                    <img class="logo" src="../../../assets/logo.png" />
                </div>
                <div style="height:28px; width: 1px; background: #EBEBEB; transform: scaleX(0.5);  margin: 0px 10px;">
                </div>
                <div class="logo-title">
                    社区
                </div>
            </div>
            <div class="menu-ctn">
                <Menu type="bbs"></Menu>
            </div>
            <div style="display: flex; align-items: center;">
                <div :title="'欢迎您，' + userInfo.name" class="head_box" @click="goToPersonCenter"
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; ">
                    <img v-if="userInfo.photoMedia" class="head" :src="`${userInfo.photoMedia}`">
                    <a-avatar v-else style="background: #00a870;">
                        {{ userInfo.name[userInfo.name.length - 1] }}
                    </a-avatar>
                    <span
                        style="font-size: 14px;font-weight: normal;color: rgba(0, 0, 0, 0.85);padding-left: 7px; ">欢迎您，</span>
                    <span
                        style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;width: 42px;color: rgba(0, 0, 0, 0.85);">{{
                            userInfo.name
                        }}</span>
                </div>
                <div class="operations">
                    <div class="operation" style="display: flex; align-items: center;">
                        <a-tooltip title="搜索">
                            <img class="logos" src="../../../assets/search.png" @click="goToSearch"/>
                        </a-tooltip>
                    </div>
                    <div class="tips_box operation" style="white-space: nowrap;position: relative;">
                        
                        <a-tooltip title="查看消息" @click="headTipsVisible = true; ">
                            <a-badge :count="userInfo.noReadCount">
                                <img class="logos" src="../../../assets/tip.png">
                            </a-badge>
                        </a-tooltip>
                        <div>
						<HeadTips
                        v-show="headTipsVisible"
							:class="headTipsVisible ? 'animate__animated animate__fadeIn' : ' none'"
							:visible="headTipsVisible" @onClose="headTipsVisible = false">
						</HeadTips>
					</div>
                    </div>
                    <div class="close_box operation">
                        <a-popconfirm title="请确认是否注销登录?" ok-text="是" cancel-text="否" @confirm="logout">
                            <img class="logos" src="../../../assets/close.png">
                        </a-popconfirm>

                    </div>
                </div>
            </div>
        </div>
        <Search></Search>
    </div>
</template>

<style lang="less" scoped>
.bbs-header {
    display: flex;
    padding: 0px 0px 0px 0px;
    align-items: center;
    height: 60px;
    //width: calc(100% - 165px);
    border-bottom: 1px solid #eee;
    box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.1);
    .logo-ctn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex:1;
        min-width: 220px;
        .logo {
            height: 40px;
        }

        .logo-title {
            font-size: 16px;
            color: #000;
        }
    }

    .menu-ctn {
        padding: 0px 16px;
        flex: 4;
        text-align: center;
    }

    .head_box {
        display: flex;
        align-items: center;
        padding: 0px 16px !important;
        height: 60px;
        align-items: center;
        cursor: pointer;
        border-left: 1px solid #EBEBEB;
        border-right: 1px solid #EBEBEB;
        padding: 0 5px;
    }
    .operations {
        padding: 0px 16px;
        display: flex;
        .operation + .operation {
            margin-left: 16px;
        }
    }
    .head {
        border-radius: 100%;
        width: 40px;
        height: 40px;
    }

    .close_box {
        border-left: 1px solid #EBEBEB;
        height: 60px;
        padding-left: 16px;
        display: flex;
        align-items: center;
    }

    .tips_box {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .logos {
	width: 36px;
    padding: 8px;
	height: auto;
	cursor: pointer;
	border-radius: 5px;
}

.logos:hover {
	background-color: rgba(48, 83, 235, 0.25);
}
}
</style>

<script>
import Menu from '../../../components/common/menu.vue';
import { mapState } from 'vuex'
import { userInfo } from 'os';
import Search from './search.vue';
import { getUserInfo, logout } from '@/api/anotherIndex'
import HeadTips from '@/components/headTips.vue'
export default {
    components: {
        Menu,
        Search,
        HeadTips
    },
    computed: {
        ...mapState(['userInfo']),
    },
    data() {
		return {
			headTipsVisible: false,
			msgHover: false,
			head: this.$store.state.userInfo.photoMedia,
			addPlatformModalVisible: false,
			inviteCode: '',
			loading: false,
			list: []
		}
	},
    methods: {
        headTipsOver() {
			this.headTipsVisible = true
		},
        goToSearch() {
            this.$router.push({
                path: '/search'
            })
        },
        async getUserInfo() {
            const res = await getUserInfo()
            if (res.success) {
                this.$store.commit('setUserInfo', res.data)
            }
            else if (res.code === 401) {
                localStorage.clear();
                this.$router.replace({
                    name: 'login'
                });
            }
        },
        async logout() {
            const res = await logout()
            if (res.success) {
                window.localStorage.clear();
                this.$store.commit('logout')
                this.$message.success('已成功注销登录')
                this.$router.push({ path: '/login' })

            }
        },
        goToPersonCenter() {
            this.$router.push({
                name: 'basicInformation'
            })
        }
    },
    created() {
		if (localStorage.getItem('Authorization')) {
			this.$store.dispatch('callForUserInfo')
		}
	}
}
</script>